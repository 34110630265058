import React from "react"
import { Link } from "gatsby"
import AuraLogo from "./aura-logo"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import Eu2020Logo from "./eu2020-logo"
import Container from "react-bootstrap/Container"
import EnFlag from "./en-flag"
import DeFlag from "./de-flag"
import HuFlag from "./hu-flag"

const HeaderDe = ({ showEu2020 = false }) => {
  return (
    <header>
      {/*{showEu2020 ? (*/}
      {/*  <Container fluid="md">*/}
      {/*    <div*/}
      {/*      data-sal="slide-down"*/}
      {/*      data-sal-delay="300"*/}
      {/*      data-sal-easing="ease"*/}
      {/*      style={{*/}
      {/*        position: "absolute",*/}
      {/*        right: "0px",*/}
      {/*        top: "0",*/}
      {/*        zIndex: "100",*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      <div style={{ height: "130px", width: "289px" }}>*/}
      {/*        <Link aria-label="Széchenyi 2020" to="/atlathatosag/ginop-2019-10-01/">*/}
      {/*          <Eu2020Logo/>*/}
      {/*        </Link>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </Container>*/}
      {/*) : (*/}
      {/*  ""*/}
      {/*)}*/}

      <Navbar bg="light" expand="md">
        <Navbar.Brand href="/">
          <AuraLogo/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto align-middle">
            {/*<Nav.Link href="/hirek/">Hírek</Nav.Link>*/}
            <Nav.Link href="/rolunk/index.de/">Über uns</Nav.Link>
            {/*<Nav.Link href="/tudastar/">Tudástár</Nav.Link>*/}
            {/*<Nav.Link href="/jelentkezes/">Jelentkezés</Nav.Link>*/}
            {/*<Nav.Link href="/gyik/">GYIK</Nav.Link>*/}
            <Nav.Link href="/de/elerhetoseg/">Kontakt</Nav.Link>
            {/*<Nav.Link href="/tamogatas/">Támogatás</Nav.Link>*/}
            {/*<Nav.Link href="/atlathatosag/">Átláthatóság</Nav.Link>*/}
            <Nav.Link href="/"><HuFlag/></Nav.Link>
            <Nav.Link href="/en/"><EnFlag/></Nav.Link>
          </Nav>
          {showEu2020 ? (
            ""
          ) : (
            ""
            // <Form inline>
            //   <FormControl
            //     type="text"
            //     name="q"
            //     placeholder="Keresés"
            //     className="mr-sm-2"
            //   />
            //   <Button variant="outline-success">Keresés</Button>
            // </Form>
          )}
        </Navbar.Collapse>
      </Navbar>
    </header>
  )
}

export default HeaderDe
