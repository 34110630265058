import React from "react"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import { FaBook, FaEnvelope, FaHandsHelping } from "react-icons/fa"
import { Link } from "gatsby"

const LinkBandDe = () => {
  return (
    <Container className="mt-4 mb-4 text-center">
      <Row>
        {/*<Col>*/}
        {/*  <h2>*/}
        {/*    <Link to="/tamogatas/" title="Támogatás">*/}
        {/*      <FaHandsHelping />*/}
        {/*    </Link>*/}
        {/*  </h2>*/}
        {/*</Col>*/}
        {/*<Col>*/}
        {/*  <h2>*/}
        {/*    <Link to="/tudastar/" title="Tudástár">*/}
        {/*      <FaBook />*/}
        {/*    </Link>*/}
        {/*  </h2>*/}
        {/*</Col>*/}
        <Col>
          <h2>
            <Link to="/de/elerhetoseg/" title="Kontakt">
              <FaEnvelope />
            </Link>
          </h2>
        </Col>
      </Row>
    </Container>
  )
}

export default LinkBandDe
