import React from "react"
import "../scss/bootstrap-custom.scss"
import "../lib/animate-3.7.2.css"

import HeaderDe from "./header.de"
import Footer from "./footer"
import LinkBandDe from "./linkband.de"
import SEO from "./seo"

const LayoutDe = ({ showEu2020, location, description, lang, meta, title, children }) => {
  return (
    <>
      <SEO location={location} description={description} lang={lang} meta={meta} title={title}/>
      <HeaderDe lang={lang} showEu2020={showEu2020}/>
      <main>{children}</main>
      <LinkBandDe lang={lang}/>
      <Footer lang={lang}/>
    </>
  )
}

export default LayoutDe
